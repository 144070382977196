import { FC, ReactElement } from 'react'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from 'themes'

import {
  EventHandlerProvider,
  GuidedTourProvider,
  MarketConditionsProvider,
  MenuModalProvider,
  MissingInternetProvider,
  OverlayProvider,
  ProductTourProvider,
  QueryParamsProvider,
  RouteHistoryProvider,
  RouteProtectionProvider,
  SavingProvider,
} from 'services/contexts'

interface IProps {
  children: ReactElement<any, any> | null
}

const Providers: FC<IProps> = ({ children }) => (
  <ThemeProvider theme={defaultTheme}>
    <OverlayProvider>
      <MissingInternetProvider>
        <QueryParamsProvider>
          <MenuModalProvider>
            <MarketConditionsProvider>
              <GuidedTourProvider>
                <RouteHistoryProvider>
                  <RouteProtectionProvider>
                    <EventHandlerProvider>
                      <SavingProvider>
                        <ProductTourProvider>
                          {/* VWO - AB Testing. Uncomment to enable */}
                          {/* <ABVariationsProvider>{children}</ABVariationsProvider> */}
                          {children}
                        </ProductTourProvider>
                      </SavingProvider>
                    </EventHandlerProvider>
                  </RouteProtectionProvider>
                </RouteHistoryProvider>
              </GuidedTourProvider>
            </MarketConditionsProvider>
          </MenuModalProvider>
        </QueryParamsProvider>
      </MissingInternetProvider>
    </OverlayProvider>
  </ThemeProvider>
)

export default Providers
